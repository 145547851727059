
















































































































































import Search from '@/components/shared/Search.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import map from 'lodash/map';
import find from 'lodash/find';
import CustomerAddModal from '@/components/customers/CustomerAddModal.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    Search,
    LoadingIndicator,
    EmptyDataset,
    FetchFailure,
    CustomerAddModal
  },
  data() {
    return {
      searchTerms: '' as string,
      currentPage: 1 as number,
      perPage: 25 as number,
      customersToMerge: [] as Customer[],
      hoveredCustomerId: null as number | null
    };
  },
  computed: {
    ...mapStores(useCustomersStore, useUsersStore),
    customers(): Customer[] {
      return this.customersStore.entities;
    },
    rows(): number {
      return this.customersStore.pagination.count;
    },
    pageCount(): number {
      return this.customersStore.pagination.page_count;
    },
    isFetching(): boolean {
      return this.customersStore.fetching;
    },
    errorOccurred(): boolean {
      return this.customersStore.failure;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    }
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    fetchCustomers(force: boolean = false) {
      if (force) {
        window.scrollTo(0, 0);
      }
      this.customersStore.findAllPaginated({
        page: this.currentPage,
        force,
        extraQueryParams: { search: this.searchTerms }
      });
    },
    customerClicked(event, customer: Customer) {
      event.preventDefault();
      if (event.target.parentElement.classList.contains('custom-checkbox')) {
        if (find(this.customersToMerge, customer)) {
          this.customersToMerge = this.customersToMerge.filter(
            (currentCustomer: any) => {
              return currentCustomer.id !== customer.id;
            }
          );
        } else {
          this.customersToMerge = (this.customersToMerge as any).concat([
            customer
          ]);
        }
      } else {
        this.$router.push({
          name: 'CustomersView',
          params: { id: customer.id.toString() }
        });
      }
    },
    goToCustomersMerge() {
      this.$router.push({
        name: 'CustomersMerge',
        query: { ids: map(this.customersToMerge, customer => customer.id) }
      });
    },
    search(value: string) {
      this.currentPage = 1;
      this.searchTerms = value;
      this.fetchCustomers(true);
    },
    isCustomerSelectedForMerge(customer: Customer) {
      return find(this.customersToMerge, customer);
    },
    openAddCustomerModal() {
      this.$bvModal.show('customer-add-modal');
    }
  }
});
